import { useRouter } from 'next/router'
import { useAuth0 } from '@auth0/auth0-react'

import { EPages } from '@types'

export default function Home() {
  const { isAuthenticated, isLoading } = useAuth0()
  const router = useRouter()

  if (!isLoading && !isAuthenticated) {
    router.push(EPages.login)
  }

  return null
}

Home.withoutLayout = true
